import AccountAddressForm from '~/src/components/profile/account/address/AccountAddressForm'
import DocumentTitleInjector from '~/src/components/root/DocumentTitleInjector'
import ContentContainer from '~/src/components/utils/layout/ContentContainer'

const Address = () => (
  <ContentContainer>
    <DocumentTitleInjector pageName={'ADDRESS'} />
    <AccountAddressForm />
  </ContentContainer>
)

export default Address
